<template>
  <div class="py-2">
    <h6>{{ index + 1 }}. {{ name }}</h6>
    <v-data-table
      :headers="headers"
      :items="answers"
      id="sortable"
      hide-default-footer
      :ref="`sortableTable-${index}`"
      :items-per-page="-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td class="py-4">
            <basic-input
              :name="`score-${item.quizId}`"
              :value.sync="item.score"
              :required="true"
              :state="validateState(`score-${item.quizId}`)"
              :invalid-feedback="errors.first(`score-${item.quizId}`)"
              v-validate="{
                required: true,
                max_value: 100,
              }"
              data-vv-as="Điểm"
              class="score-input"
              input-number
              maxlength="3"
            ></basic-input>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Sortable from 'sortablejs';
export default {
  name: 'AnswerTable',
  props: {
    answers: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      headers: [
        {
          text: 'Câu trả lời',
          value: 'answer',
          sortable: false,
          width: '50%',
        },
        {
          text: 'Điểm',
          value: 'score',
          sortable: false,
        },
      ],
      isRequireAnswer: false,
      isInvalid: false,
    };
  },
  mounted() {
    new Sortable(
      this.$refs[`sortableTable-${this.index}`].$el.getElementsByTagName(
        'tbody',
      )[0],
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
      },
    );
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          return (this.isInvalid = false);
        }
        this.isInvalid = true;
        return;
      });
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.answers.splice(oldIndex, 1)[0];
      this.answers.splice(newIndex, 0, movedItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
</style>

<style lang="scss">
.score-input {
  margin-bottom: 0;
}
</style>
